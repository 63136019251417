<template>

  <ion-list v-for="item in collections" :collection="item" :key="item.id">
    <ion-item @click="openModal(item)" detail>
    <ion-thumbnail slot="start">
        <img :title="item.username" :src="utils.publicKeyImageUrl(item.publicKey)" />
    </ion-thumbnail>
        <ion-label>{{ item.username }}</ion-label>
        <ion-badge color="secondary">{{ item.count }}</ion-badge>
    </ion-item>
  </ion-list>
</template>

<script>
import { defineComponent } from "vue";
import { Utils } from "../services/utils";
import { 
  IonBadge,
  IonItem,
  IonList,

  modalController,
} from '@ionic/vue';
import CollectionHoldingModal from '../modals/CollectionHoldingModal.vue';

export default defineComponent({
    components: { 
        IonBadge, 
        IonItem,
        IonList, 
    },

    methods: {
        async openModal(collection) {
            const modal = await modalController
                .create({
                component: CollectionHoldingModal,
                cssClass: 'my-custom-class',
                componentProps: {
                    title: 'Collection Holdings',
                    collection: collection
                },
                })
            await modal.present();
            await modal.onDidDismiss();
        },
    },

    props: {
        collections: null
    },

    setup() {
        const utils = new Utils();

        return {
            utils: utils,
        }
    }

});
</script>
