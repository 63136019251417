<template>
  <ion-page>
    <ion-header>

      <ion-toolbar>
        <div slot="start" class="ion-padding-start">
          <img class="h-6" src="/assets/images/wollo-brand-green.svg" />
        </div>
        <ion-buttons slot="end" class="ion-padding-end">
          <div v-if="exchangeRates.USDCentsPerDeSoExchangeRate" class="flex items-center text-sm">
            <img class="deso-xs" src="/assets/images/deso.svg" /> ≈
            {{ utils.displayFiat(exchangeRates.USDCentsPerDeSoExchangeRate) }} USD
          </div>
        </ion-buttons>

        <div class="flex justify-center">
        </div>
      </ion-toolbar>

    </ion-header>

    <ion-content  class="">

      <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
        <ion-refresher-content>
        </ion-refresher-content>
      </ion-refresher>

      <div v-if="loading">

        <ion-list>
          <ion-item lines="none">
            <ion-label>Loading</ion-label>
            <ion-spinner slot="start"></ion-spinner>
          </ion-item>
        </ion-list>

      </div>
      <div v-else>
        <ion-radio-group v-model="sortType">
          <div class="grid grid-cols-3 items-start"> 

          <ion-item lines="none">
            <ion-label>Purchased</ion-label>
            <ion-radio value="purchased"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <ion-label>Name</ion-label>
            <ion-radio value="name"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <ion-label>Count</ion-label>
            <ion-radio value="count"></ion-radio>
          </ion-item>
          </div>

        </ion-radio-group>

        <collection-grid class="mt-3" :collections="collections" />
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { ref, computed, defineComponent } from 'vue';
import { useStore } from "vuex";
import {
  // IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem, 
  IonLabel,
  IonList,
  // IonListHeader,
  IonPage,
  IonSpinner,
  // IonTitle,
  IonRadio,
  IonRadioGroup,
  IonToolbar,

  IonRefresher,
  IonRefresherContent,

} from '@ionic/vue';

import { Utils } from "../services/utils";
import CollectionGrid from "@/components/CollectionGrid.vue";

export default defineComponent({
  components: { 
    // IonButton, 
    IonButtons, 
    IonContent, IonHeader,
    IonItem, 
    IonLabel,
    IonList,
    // IonListHeader,
    IonPage, 
    IonSpinner,
    // IonTitle, 
    IonRadio,
    IonRadioGroup,
    IonToolbar, 

    IonRefresher,
    IonRefresherContent,

    CollectionGrid,
  },

  setup() {
    const utils = new Utils();
    const store = useStore();
    const loading = ref(true);
    const sortType = ref('purchased');

    store.dispatch("loadAccounts").then(function() {
      store.dispatch("loadNfts").then(function() {
        loading.value = false;
      });
    }) 

    const accounts = computed(function () {
      return store.getters.accounts;
    });

    const nfts = computed(function () {
      return store.getters.nfts;
    });

    const exchangeRates = computed(function () {
      return store.getters.exchangeRates;
    });

    const collections = computed(function () {
      let list = [];
      if (nfts.value) {
        const collectionNames = [];
        const collectionNfts = [];
        const collectionLast = [];
        const collectionFirst = [];

        const map = new Map();
        for (const element of nfts.value) {
          if (element.collection && element.collection.username) {
            if (!collectionNames.includes(element.collection.username)) {
              // map[element.collection.publicKey] = element.collection;
              map.set(element.collection.publicKey, element.collection);
              collectionNames.push(element.collection.username);
              collectionNfts[element.collection.publicKey] = [];
              collectionNfts[element.collection.publicKey].push(element);
              collectionLast[element.collection.publicKey] = element.timestampNanos;
              collectionFirst[element.collection.publicKey] = element.timestampNanos;
            } else {
              collectionNfts[element.collection.publicKey].push(element);
              if (element.timestampNanos > collectionLast[element.collection.publicKey]) {
                collectionLast[element.collection.publicKey] = element.timestampNanos;
              }

              if (element.timestampNanos < collectionFirst[element.collection.publicKey]) {
                collectionFirst[element.collection.publicKey] = element.timestampNanos;
              }
            }
          }
        }
        // let list = map.toArray();
        const l = Array.from(map.values());
        // update the collection to have the count
        for (const element of l) {
          element.count = collectionNfts[element.publicKey].length;
          element.nfts = collectionNfts[element.publicKey];
          element.firstAdded = collectionFirst[element.publicKey];
          element.lastAdded = collectionLast[element.publicKey];
          list.push(element);
        }

        if (sortType.value == 'name') {
          list = list.sort((a, b) => a.username.localeCompare(b.username));
        } else if (sortType.value == 'purchased') {
          list = list.sort((a, b) => b.lastAdded - a.lastAdded);
          // list = list.sort((a, b) => a.lastAdded.localeCompare(b.lastAdded));
        } else {
          list = list.sort((a, b) => b.count - a.count);
        }
      }
      return list;
    });

    function refresh(event) {
      loading.value = true;
      store.dispatch("loadAccounts").then(function() {
        store.dispatch("loadNfts").then(function() {
          loading.value = false;
          event.target.complete();
        });
      }) 
    }

    return {
      utils: utils,

      loading: loading,
      collections: collections,
      accounts: accounts,
      sortType: sortType,

      exchangeRates: exchangeRates,

      nfts: nfts,

      refresh: refresh,
    };
  },

});
</script>
